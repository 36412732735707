var exports = {};
exports = {
  A: {
    A: {
      J: 0,
      D: 0,
      E: 0.0259843,
      F: 0.0649608,
      A: 0,
      B: 0.389765,
      NC: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "NC", "J", "D", "E", "F", "A", "B", "", "", ""],
    E: "IE",
    F: {
      NC: 962323200,
      J: 998870400,
      D: 1161129600,
      E: 1237420800,
      F: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      "0": 0.02721,
      "1": 0.176865,
      "2": 0.14512,
      "3": 2.54867,
      C: 0.004535,
      K: 0.004535,
      L: 0.004535,
      G: 0.004535,
      M: 0,
      N: 0.004535,
      O: 0.013605,
      P: 0,
      Q: 0.00907,
      R: 0.00907,
      S: 0.00907,
      T: 0.00907,
      U: 0.004535,
      V: 0.00907,
      W: 0.004535,
      X: 0.004535,
      Y: 0.004535,
      Z: 0.00907,
      a: 0,
      b: 0.013605,
      c: 0,
      d: 0,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0.013605,
      j: 0,
      k: 0,
      l: 0,
      m: 0,
      r: 0,
      s: 0,
      t: 0,
      u: 0.00907,
      v: 0.00907,
      w: 0.07256,
      x: 0.013605,
      y: 0.12698,
      z: 0.12698,
      H: 1.76865
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "K", "L", "G", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "H", "", "", ""],
    E: "Edge",
    F: {
      "0": 1683158400,
      "1": 1685664000,
      "2": 1689897600,
      "3": 1692576000,
      C: 1438128000,
      K: 1447286400,
      L: 1470096000,
      G: 1491868800,
      M: 1508198400,
      N: 1525046400,
      O: 1542067200,
      P: 1579046400,
      Q: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      c: 1630627200,
      d: 1632441600,
      e: 1634774400,
      f: 1637539200,
      g: 1641427200,
      h: 1643932800,
      i: 1646265600,
      j: 1649635200,
      k: 1651190400,
      l: 1653955200,
      m: 1655942400,
      r: 1659657600,
      s: 1661990400,
      t: 1664755200,
      u: 1666915200,
      v: 1670198400,
      w: 1673481600,
      x: 1675900800,
      y: 1678665600,
      z: 1680825600,
      H: 1694649600
    },
    D: {
      C: "ms",
      K: "ms",
      L: "ms",
      G: "ms",
      M: "ms",
      N: "ms",
      O: "ms"
    }
  },
  C: {
    A: {
      "0": 0.01814,
      "1": 0.01814,
      "2": 0.31745,
      "3": 0.113375,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0,
      "9": 0,
      OC: 0,
      "3B": 0,
      I: 0,
      J: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0.00907,
      C: 0,
      K: 0,
      L: 0,
      G: 0,
      M: 0,
      N: 0,
      O: 0,
      n: 0,
      o: 0,
      p: 0,
      AB: 0,
      BB: 0,
      CB: 0,
      DB: 0,
      EB: 0,
      FB: 0,
      GB: 0,
      HB: 0,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0.004535,
      RB: 0.004535,
      SB: 0.004535,
      TB: 0,
      UB: 0,
      VB: 0.01814,
      WB: 0,
      XB: 0.00907,
      YB: 0,
      ZB: 0.05442,
      aB: 0.00907,
      bB: 0.00907,
      cB: 0,
      dB: 0.013605,
      eB: 0,
      fB: 0,
      "4B": 0.004535,
      gB: 0,
      "5B": 0,
      hB: 0,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0,
      oB: 0,
      pB: 0,
      qB: 0,
      rB: 0.013605,
      q: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0,
      wB: 0.03628,
      P: 0,
      Q: 0,
      R: 0,
      "6B": 0,
      S: 0.004535,
      T: 0,
      U: 0,
      V: 0,
      W: 0,
      X: 0.004535,
      Y: 0.004535,
      Z: 0,
      a: 0.004535,
      b: 0,
      c: 0,
      d: 0.004535,
      e: 0,
      f: 0,
      g: 0,
      h: 0,
      i: 0,
      j: 0,
      k: 0,
      l: 0.11791,
      m: 0.022675,
      r: 0,
      s: 0.004535,
      t: 0.004535,
      u: 0.004535,
      v: 0.00907,
      w: 0.00907,
      x: 0.00907,
      y: 0.00907,
      z: 0.06349,
      H: 1.56457,
      xB: 0.13605,
      "7B": 0,
      "8B": 0,
      PC: 0,
      QC: 0,
      RC: 0
    },
    B: "moz",
    C: ["OC", "3B", "QC", "RC", "I", "4", "J", "D", "E", "F", "A", "B", "C", "K", "L", "G", "M", "N", "O", "5", "n", "o", "p", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "4B", "gB", "5B", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "q", "sB", "tB", "uB", "vB", "wB", "P", "Q", "R", "6B", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "H", "xB", "7B", "8B", "PC"],
    E: "Firefox",
    F: {
      "0": 1683590400,
      "1": 1686009600,
      "2": 1688428800,
      "3": 1690848000,
      "4": 1308614400,
      "5": 1357603200,
      "6": 1372118400,
      "7": 1375747200,
      "8": 1379376000,
      "9": 1386633600,
      OC: 1161648000,
      "3B": 1213660800,
      QC: 1246320000,
      RC: 1264032000,
      I: 1300752000,
      J: 1313452800,
      D: 1317081600,
      E: 1317081600,
      F: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      K: 1335225600,
      L: 1338854400,
      G: 1342483200,
      M: 1346112000,
      N: 1349740800,
      O: 1353628800,
      n: 1361232000,
      o: 1364860800,
      p: 1368489600,
      AB: 1391472000,
      BB: 1395100800,
      CB: 1398729600,
      DB: 1402358400,
      EB: 1405987200,
      FB: 1409616000,
      GB: 1413244800,
      HB: 1417392000,
      IB: 1421107200,
      JB: 1424736000,
      KB: 1428278400,
      LB: 1431475200,
      MB: 1435881600,
      NB: 1439251200,
      OB: 1442880000,
      PB: 1446508800,
      QB: 1450137600,
      RB: 1453852800,
      SB: 1457395200,
      TB: 1461628800,
      UB: 1465257600,
      VB: 1470096000,
      WB: 1474329600,
      XB: 1479168000,
      YB: 1485216000,
      ZB: 1488844800,
      aB: 1492560000,
      bB: 1497312000,
      cB: 1502150400,
      dB: 1506556800,
      eB: 1510617600,
      fB: 1516665600,
      "4B": 1520985600,
      gB: 1525824000,
      "5B": 1529971200,
      hB: 1536105600,
      iB: 1540252800,
      jB: 1544486400,
      kB: 1548720000,
      lB: 1552953600,
      mB: 1558396800,
      nB: 1562630400,
      oB: 1567468800,
      pB: 1571788800,
      qB: 1575331200,
      rB: 1578355200,
      q: 1581379200,
      sB: 1583798400,
      tB: 1586304000,
      uB: 1588636800,
      vB: 1591056000,
      wB: 1593475200,
      P: 1595894400,
      Q: 1598313600,
      R: 1600732800,
      "6B": 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      c: 1633392000,
      d: 1635811200,
      e: 1638835200,
      f: 1641859200,
      g: 1644364800,
      h: 1646697600,
      i: 1649116800,
      j: 1651536000,
      k: 1653955200,
      l: 1656374400,
      m: 1658793600,
      r: 1661212800,
      s: 1663632000,
      t: 1666051200,
      u: 1668470400,
      v: 1670889600,
      w: 1673913600,
      x: 1676332800,
      y: 1678752000,
      z: 1681171200,
      H: 1693267200,
      xB: 1695686400,
      "7B": null,
      "8B": null,
      PC: null
    }
  },
  D: {
    A: {
      "0": 0.15419,
      "1": 0.829905,
      "2": 1.44213,
      "3": 15.3691,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0,
      "9": 0,
      I: 0,
      J: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0,
      C: 0,
      K: 0,
      L: 0,
      G: 0,
      M: 0,
      N: 0,
      O: 0,
      n: 0,
      o: 0,
      p: 0,
      AB: 0,
      BB: 0,
      CB: 0,
      DB: 0,
      EB: 0,
      FB: 0,
      GB: 0,
      HB: 0.004535,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0.013605,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0,
      UB: 0.00907,
      VB: 0.01814,
      WB: 0.03628,
      XB: 0.00907,
      YB: 0.004535,
      ZB: 0,
      aB: 0.00907,
      bB: 0,
      cB: 0.00907,
      dB: 0.031745,
      eB: 0.00907,
      fB: 0.00907,
      "4B": 0,
      gB: 0.02721,
      "5B": 0.004535,
      hB: 0.004535,
      iB: 0.004535,
      jB: 0,
      kB: 0.013605,
      lB: 0.022675,
      mB: 0.004535,
      nB: 0.031745,
      oB: 0.06349,
      pB: 0.077095,
      qB: 0.022675,
      rB: 0.031745,
      q: 0.040815,
      sB: 0.040815,
      tB: 0.040815,
      uB: 0.104305,
      vB: 0.03628,
      wB: 0.04535,
      P: 0.204075,
      Q: 0.068025,
      R: 0.06349,
      S: 0.113375,
      T: 0.03628,
      U: 0.086165,
      V: 0.0907,
      W: 0.09977,
      X: 0.058955,
      Y: 0.04535,
      Z: 0.068025,
      a: 0.07256,
      b: 0.02721,
      c: 0.131515,
      d: 0.02721,
      e: 0.013605,
      f: 0.022675,
      g: 0.02721,
      h: 0.068025,
      i: 0.04535,
      j: 0.031745,
      k: 0.03628,
      l: 0.040815,
      m: 0.21768,
      r: 0.04535,
      s: 0.049885,
      t: 0.05442,
      u: 0.17233,
      v: 0.213145,
      w: 1.78679,
      x: 0.185935,
      y: 0.32652,
      z: 0.24489,
      H: 3.86382,
      xB: 0.022675,
      "7B": 0.013605,
      "8B": 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "I", "4", "J", "D", "E", "F", "A", "B", "C", "K", "L", "G", "M", "N", "O", "5", "n", "o", "p", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "4B", "gB", "5B", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "q", "sB", "tB", "uB", "vB", "wB", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "H", "xB", "7B", "8B"],
    E: "Chrome",
    F: {
      "0": 1682985600,
      "1": 1685404800,
      "2": 1689724800,
      "3": 1692057600,
      "4": 1274745600,
      "5": 1332892800,
      "6": 1348531200,
      "7": 1352246400,
      "8": 1357862400,
      "9": 1361404800,
      I: 1264377600,
      J: 1283385600,
      D: 1287619200,
      E: 1291248000,
      F: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      K: 1312243200,
      L: 1316131200,
      G: 1316131200,
      M: 1319500800,
      N: 1323734400,
      O: 1328659200,
      n: 1337040000,
      o: 1340668800,
      p: 1343692800,
      AB: 1364428800,
      BB: 1369094400,
      CB: 1374105600,
      DB: 1376956800,
      EB: 1384214400,
      FB: 1389657600,
      GB: 1392940800,
      HB: 1397001600,
      IB: 1400544000,
      JB: 1405468800,
      KB: 1409011200,
      LB: 1412640000,
      MB: 1416268800,
      NB: 1421798400,
      OB: 1425513600,
      PB: 1429401600,
      QB: 1432080000,
      RB: 1437523200,
      SB: 1441152000,
      TB: 1444780800,
      UB: 1449014400,
      VB: 1453248000,
      WB: 1456963200,
      XB: 1460592000,
      YB: 1464134400,
      ZB: 1469059200,
      aB: 1472601600,
      bB: 1476230400,
      cB: 1480550400,
      dB: 1485302400,
      eB: 1489017600,
      fB: 1492560000,
      "4B": 1496707200,
      gB: 1500940800,
      "5B": 1504569600,
      hB: 1508198400,
      iB: 1512518400,
      jB: 1516752000,
      kB: 1520294400,
      lB: 1523923200,
      mB: 1527552000,
      nB: 1532390400,
      oB: 1536019200,
      pB: 1539648000,
      qB: 1543968000,
      rB: 1548720000,
      q: 1552348800,
      sB: 1555977600,
      tB: 1559606400,
      uB: 1564444800,
      vB: 1568073600,
      wB: 1571702400,
      P: 1575936000,
      Q: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      c: 1630368000,
      d: 1632268800,
      e: 1634601600,
      f: 1637020800,
      g: 1641340800,
      h: 1643673600,
      i: 1646092800,
      j: 1648512000,
      k: 1650931200,
      l: 1653350400,
      m: 1655769600,
      r: 1659398400,
      s: 1661817600,
      t: 1664236800,
      u: 1666656000,
      v: 1669680000,
      w: 1673308800,
      x: 1675728000,
      y: 1678147200,
      z: 1680566400,
      H: 1694476800,
      xB: null,
      "7B": null,
      "8B": null
    }
  },
  E: {
    A: {
      "4": 0,
      I: 0,
      J: 0,
      D: 0,
      E: 0,
      F: 0,
      A: 0,
      B: 0,
      C: 0,
      K: 0.01814,
      L: 0.0907,
      G: 0.01814,
      SC: 0,
      "9B": 0,
      TC: 0.00907,
      UC: 0,
      VC: 0,
      WC: 0.1814,
      AC: 0,
      yB: 0.004535,
      zB: 0.02721,
      BC: 0.131515,
      XC: 0.240355,
      YC: 0.040815,
      CC: 0.03628,
      DC: 0.068025,
      "0B": 0.10884,
      ZC: 0.5442,
      "1B": 0.068025,
      EC: 0.158725,
      FC: 0.15419,
      GC: 0.3628,
      HC: 0.185935,
      IC: 0.44443,
      JC: 2.50332,
      "2B": 0.09977,
      KC: 0,
      aC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "SC", "9B", "I", "4", "TC", "J", "UC", "D", "VC", "E", "F", "WC", "A", "AC", "B", "yB", "C", "zB", "K", "BC", "L", "XC", "G", "YC", "CC", "DC", "0B", "ZC", "1B", "EC", "FC", "GC", "HC", "IC", "JC", "2B", "KC", "aC", ""],
    E: "Safari",
    F: {
      "4": 1275868800,
      SC: 1205798400,
      "9B": 1226534400,
      I: 1244419200,
      TC: 1311120000,
      J: 1343174400,
      UC: 1382400000,
      D: 1382400000,
      VC: 1410998400,
      E: 1413417600,
      F: 1443657600,
      WC: 1458518400,
      A: 1474329600,
      AC: 1490572800,
      B: 1505779200,
      yB: 1522281600,
      C: 1537142400,
      zB: 1553472000,
      K: 1568851200,
      BC: 1585008000,
      L: 1600214400,
      XC: 1619395200,
      G: 1632096000,
      YC: 1635292800,
      CC: 1639353600,
      DC: 1647216000,
      "0B": 1652745600,
      ZC: 1658275200,
      "1B": 1662940800,
      EC: 1666569600,
      FC: 1670889600,
      GC: 1674432000,
      HC: 1679875200,
      IC: 1684368000,
      JC: 1690156800,
      "2B": 1695686400,
      KC: null,
      aC: null
    }
  },
  F: {
    A: {
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0,
      "9": 0,
      F: 0,
      B: 0,
      C: 0,
      G: 0,
      M: 0,
      N: 0,
      O: 0,
      n: 0,
      o: 0,
      p: 0,
      AB: 0,
      BB: 0.004535,
      CB: 0,
      DB: 0,
      EB: 0,
      FB: 0,
      GB: 0,
      HB: 0,
      IB: 0,
      JB: 0,
      KB: 0,
      LB: 0,
      MB: 0,
      NB: 0,
      OB: 0,
      PB: 0,
      QB: 0,
      RB: 0,
      SB: 0,
      TB: 0.013605,
      UB: 0,
      VB: 0,
      WB: 0,
      XB: 0,
      YB: 0,
      ZB: 0,
      aB: 0,
      bB: 0.004535,
      cB: 0.004535,
      dB: 0,
      eB: 0,
      fB: 0,
      gB: 0,
      hB: 0,
      iB: 0,
      jB: 0,
      kB: 0,
      lB: 0,
      mB: 0,
      nB: 0,
      oB: 0,
      pB: 0,
      qB: 0,
      rB: 0,
      q: 0,
      sB: 0,
      tB: 0,
      uB: 0,
      vB: 0.004535,
      wB: 0,
      P: 0,
      Q: 0,
      R: 0,
      "6B": 0,
      S: 0,
      T: 0,
      U: 0.004535,
      V: 0,
      W: 0,
      X: 0,
      Y: 0.004535,
      Z: 0,
      a: 0,
      b: 0,
      c: 0,
      d: 0,
      e: 0.05442,
      f: 0,
      g: 0.11791,
      h: 0,
      i: 0.022675,
      j: 0.26303,
      k: 0.58048,
      l: 0.64397,
      m: 0,
      bC: 0,
      cC: 0,
      dC: 0,
      eC: 0,
      yB: 0,
      LC: 0,
      fC: 0,
      zB: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "F", "bC", "cC", "dC", "eC", "B", "yB", "LC", "fC", "C", "zB", "G", "M", "N", "O", "5", "n", "o", "p", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "hB", "iB", "jB", "kB", "lB", "mB", "nB", "oB", "pB", "qB", "rB", "q", "sB", "tB", "uB", "vB", "wB", "P", "Q", "R", "6B", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "", "", ""],
    E: "Opera",
    F: {
      "5": 1390867200,
      "6": 1405987200,
      "7": 1409616000,
      "8": 1413331200,
      "9": 1417132800,
      F: 1150761600,
      bC: 1223424000,
      cC: 1251763200,
      dC: 1267488000,
      eC: 1277942400,
      B: 1292457600,
      yB: 1302566400,
      LC: 1309219200,
      fC: 1323129600,
      C: 1323129600,
      zB: 1352073600,
      G: 1372723200,
      M: 1377561600,
      N: 1381104000,
      O: 1386288000,
      n: 1393891200,
      o: 1399334400,
      p: 1401753600,
      AB: 1422316800,
      BB: 1425945600,
      CB: 1430179200,
      DB: 1433808000,
      EB: 1438646400,
      FB: 1442448000,
      GB: 1445904000,
      HB: 1449100800,
      IB: 1454371200,
      JB: 1457308800,
      KB: 1462320000,
      LB: 1465344000,
      MB: 1470096000,
      NB: 1474329600,
      OB: 1477267200,
      PB: 1481587200,
      QB: 1486425600,
      RB: 1490054400,
      SB: 1494374400,
      TB: 1498003200,
      UB: 1502236800,
      VB: 1506470400,
      WB: 1510099200,
      XB: 1515024000,
      YB: 1517961600,
      ZB: 1521676800,
      aB: 1525910400,
      bB: 1530144000,
      cB: 1534982400,
      dB: 1537833600,
      eB: 1543363200,
      fB: 1548201600,
      gB: 1554768000,
      hB: 1561593600,
      iB: 1566259200,
      jB: 1570406400,
      kB: 1573689600,
      lB: 1578441600,
      mB: 1583971200,
      nB: 1587513600,
      oB: 1592956800,
      pB: 1595894400,
      qB: 1600128000,
      rB: 1603238400,
      q: 1613520000,
      sB: 1612224000,
      tB: 1616544000,
      uB: 1619568000,
      vB: 1623715200,
      wB: 1627948800,
      P: 1631577600,
      Q: 1633392000,
      R: 1635984000,
      "6B": 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000,
      c: 1668643200,
      d: 1671062400,
      e: 1675209600,
      f: 1677024000,
      g: 1679529600,
      h: 1681948800,
      i: 1684195200,
      j: 1687219200,
      k: 1690329600,
      l: 1692748800,
      m: 1696204800
    },
    D: {
      F: "o",
      B: "o",
      C: "o",
      bC: "o",
      cC: "o",
      dC: "o",
      eC: "o",
      yB: "o",
      LC: "o",
      fC: "o",
      zB: "o"
    }
  },
  G: {
    A: {
      E: 0,
      "9B": 0,
      gC: 0,
      MC: 0.00285607,
      hC: 0.00285607,
      iC: 0.00285607,
      jC: 0.00999624,
      kC: 0.00285607,
      lC: 0.00999624,
      mC: 0.0342728,
      nC: 0.00142803,
      oC: 0.0514092,
      pC: 0.0214205,
      qC: 0.0157084,
      rC: 0.0142803,
      sC: 0.238482,
      tC: 0.00714017,
      uC: 0.0128523,
      vC: 0.155656,
      wC: 0.0499812,
      xC: 0.135663,
      yC: 0.225629,
      zC: 0.0899662,
      CC: 0.0985344,
      DC: 0.112815,
      "0B": 0.157084,
      "0C": 1.03961,
      "1B": 0.411274,
      EC: 0.761142,
      FC: 0.386997,
      GC: 0.722585,
      HC: 0.164224,
      IC: 0.489816,
      JC: 7.98414,
      "2B": 0.704021,
      KC: 0.00142803
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "9B", "gC", "MC", "hC", "iC", "jC", "E", "kC", "lC", "mC", "nC", "oC", "pC", "qC", "rC", "sC", "tC", "uC", "vC", "wC", "xC", "yC", "zC", "CC", "DC", "0B", "0C", "1B", "EC", "FC", "GC", "HC", "IC", "JC", "2B", "KC", "", ""],
    E: "Safari on iOS",
    F: {
      "9B": 1270252800,
      gC: 1283904000,
      MC: 1299628800,
      hC: 1331078400,
      iC: 1359331200,
      jC: 1394409600,
      E: 1410912000,
      kC: 1413763200,
      lC: 1442361600,
      mC: 1458518400,
      nC: 1473724800,
      oC: 1490572800,
      pC: 1505779200,
      qC: 1522281600,
      rC: 1537142400,
      sC: 1553472000,
      tC: 1568851200,
      uC: 1572220800,
      vC: 1580169600,
      wC: 1585008000,
      xC: 1600214400,
      yC: 1619395200,
      zC: 1632096000,
      CC: 1639353600,
      DC: 1647216000,
      "0B": 1652659200,
      "0C": 1658275200,
      "1B": 1662940800,
      EC: 1666569600,
      FC: 1670889600,
      GC: 1674432000,
      HC: 1679875200,
      IC: 1684368000,
      JC: 1690156800,
      "2B": 1694995200,
      KC: null
    }
  },
  H: {
    A: {
      "1C": 1.02942
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "1C", "", "", ""],
    E: "Opera Mini",
    F: {
      "1C": 1426464000
    }
  },
  I: {
    A: {
      "3B": 0,
      I: 0.0156469,
      H: 0,
      "2C": 0,
      "3C": 0.0156469,
      "4C": 0,
      "5C": 0.0156469,
      MC: 0.0547642,
      "6C": 0,
      "7C": 0.242527
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "2C", "3C", "4C", "3B", "I", "5C", "MC", "6C", "7C", "H", "", "", ""],
    E: "Android Browser",
    F: {
      "2C": 1256515200,
      "3C": 1274313600,
      "4C": 1291593600,
      "3B": 1298332800,
      I: 1318896000,
      "5C": 1341792000,
      MC: 1374624000,
      "6C": 1386547200,
      "7C": 1401667200,
      H: 1694476800
    }
  },
  J: {
    A: {
      D: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      D: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      q: 0,
      yB: 0,
      LC: 0,
      zB: 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "yB", "LC", "C", "zB", "q", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      yB: 1314835200,
      LC: 1318291200,
      C: 1330300800,
      zB: 1349740800,
      q: 1673827200
    },
    D: {
      q: "webkit"
    }
  },
  L: {
    A: {
      H: 36.3803
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "H", "", "", ""],
    E: "Chrome for Android",
    F: {
      H: 1694476800
    }
  },
  M: {
    A: {
      xB: 0.278664
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "xB", "", "", ""],
    E: "Firefox for Android",
    F: {
      xB: 1695686400
    }
  },
  N: {
    A: {
      A: 0,
      B: 0
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      "0B": 0.890632
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "0B", "", "", ""],
    E: "UC Browser for Android",
    F: {
      "0B": 1687132800
    },
    D: {
      "0B": "webkit"
    }
  },
  P: {
    A: {
      I: 0.107075,
      n: 0.0642449,
      o: 0.139197,
      p: 1.87381,
      "8C": 0,
      "9C": 0,
      AD: 0.0321224,
      BD: 0,
      CD: 0,
      AC: 0,
      DD: 0.0107075,
      ED: 0,
      FD: 0.021415,
      GD: 0.0107075,
      HD: 0,
      "1B": 0.021415,
      "2B": 0.0321224,
      ID: 0.021415,
      JD: 0.0535374
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "I", "8C", "9C", "AD", "BD", "CD", "AC", "DD", "ED", "FD", "GD", "HD", "1B", "2B", "ID", "JD", "n", "o", "p", "", "", ""],
    E: "Samsung Internet",
    F: {
      I: 1461024000,
      "8C": 1481846400,
      "9C": 1509408000,
      AD: 1528329600,
      BD: 1546128000,
      CD: 1554163200,
      AC: 1567900800,
      DD: 1582588800,
      ED: 1593475200,
      FD: 1605657600,
      GD: 1618531200,
      HD: 1629072000,
      "1B": 1640736000,
      "2B": 1651708800,
      ID: 1659657600,
      JD: 1667260800,
      n: 1677369600,
      o: 1684454400,
      p: 1689292800
    }
  },
  Q: {
    A: {
      BC: 0.16392
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "BC", "", "", ""],
    E: "QQ Browser",
    F: {
      BC: 1663718400
    }
  },
  R: {
    A: {
      KD: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "KD", "", "", ""],
    E: "Baidu Browser",
    F: {
      KD: 1663027200
    }
  },
  S: {
    A: {
      LD: 0.05464,
      MD: 0
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "LD", "MD", "", "", ""],
    E: "KaiOS Browser",
    F: {
      LD: 1527811200,
      MD: 1631664000
    }
  }
};
export default exports;